import React from "react";
import style from "./camouflage.module.css";


function Camouflage() {

  return(
    <div className={style["body-home"]}>
      <div className={style["camouflage"]}>
        <div className={style["reflection"]}></div>
      </div>

      <div className={style["camouflage"]}>
        <div className={style["reflection"]}></div>
      </div>

      <div className={style["camouflage"]}>
        <div className={style["reflection"]}></div>
      </div>

      <div className={style["camouflage"]}>
        <div className={style["reflection"]}></div>
      </div>
    </div>
  ) ;
}

export default Camouflage;
