import React, { useState } from "react";
import style from "./body-home.module.css";

import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";

import LowHierarchyBody from "./lowHierarchybody/lowHierarchyBody";
import Camouflage from "./camouflage/camouflage";

function BodyHome() {
  const { username } = useParams();
  const navigate = useNavigate();
  const [ultimateMessage, setUltimateMessage] = useState({});
  const [showCamuflage, setShowCamuflage] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  function handleChangeNivel(value) {
    navigate(`/home/${username}}/level/${value}`);

    if (value === "B1") {
      localStorage.setItem("local", "B1 Ipê");
    }
    if (value === "B2") {
      localStorage.setItem("local", "B1 Tico Tico");
    }
    if (value === "B1-Aux") {
      localStorage.setItem("local", "B1 Auxiliar");
    }
  }

  async function getMessage() {

    // altere o get para esse rota para relizar testes internos
    //http://34.207.150.2:8000/communication/communication_mnt?username=${user.username}

    await axios
      .get(
        `https://mcs.sipremo.com/api/operacao/get_mensages_by_username?username=${user.username}`
      )
      .then((res) => {
        setUltimateMessage(res.data[0]);
        setShowCamuflage(true);
      });
  }

  React.useEffect(() => {
    getMessage();
  }, []);

  return parseInt(user.hierarchy_database) === 1 ? (
    showCamuflage ? (
      <LowHierarchyBody ultimateMessage={ultimateMessage ? ultimateMessage : false} />
    ) : (
      <Camouflage />
    )
  ) : (
    <div className={style["body-home"]}>
      <div className={style["body-body-home"]}>
        <div className={style["title-structure"]}>
          <p>Selecione uma Estrutura</p>
        </div>
        <div className={style["content-body-home"]}>
          <div
            className={style["button-estructure"]}
            onClick={() => handleChangeNivel("B1")}
          >
            B1 Ipê
          </div>
          <div
            className={style["button-estructure"]}
            onClick={() => handleChangeNivel("B1-Aux")}
          >
            B1 Auxiliar
          </div>
          <div
            className={style["button-estructure"]}
            id={style["Button-Structure-TicoTico"]}
            onClick={() => handleChangeNivel("B2")}
          >
            B2 Tico Tico
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyHome;
