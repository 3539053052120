import React from "react";
import style from "./header.module.css";

import SipremoLogo from "../../images/sipremo.png";

import MenuIcon from '@mui/icons-material/Menu';

import { useNavigate, useParams } from "react-router-dom";

function Header({ menu }) {
  const { username } = useParams();

  const [menuBoard, setMenuBoard] = React.useState(false);

 
  const handleMenu = () =>{
    setMenuBoard(!menuBoard)
    menu(!menuBoard)
  }


  return (
    <div
      className={ style["background-body"]}
    >
      <div className={style.menu}>
        <MenuIcon onClick={handleMenu}/>
      </div>
      <div className={style.options}>
        <img src={SipremoLogo}></img>
      </div>
    </div>
  );
}

export default Header;
